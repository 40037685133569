export const MariaPrimary = '#251863'
export const MariaSecondary = '#ffe200'
export const MariaSecondGradientColor = '#E73C70'
export const MariaText = '#00ffff'

// export const MariaPrimary = '#E73C70'
// export const MariaSecondary = '#ffe200'
// export const MariaSecondGradientColor = '#00ffff'
// export const MariaText = '#251863'

// export const MariaPrimary = '#5c34fa' //darker blue
// export const MariaSecondary = '#ffc53f' //yellowish
// export const MariaSecondGradientColor = '#c700a0' //fuchsia
// export const MariaText = '#00e0e0' //aqua
