import React from "react"
import styled from "styled-components"
import {MariaPrimary, MariaSecondary, MariaSecondGradientColor, MariaText} from "../utils/colors"

import NavigationMenu from "./nav"
import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Wrapper>
        <NavigationMenu/>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(32),
            color: `${MariaText}`,
          }}
        >

          {/* <header>{header}</header> */}
          <main>{children}
          </main>

        </div>
        <Footer>          
          © {new Date().getFullYear()}
          {` `} Maria Dermentzi
        </Footer>

      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
  max-height: 100%;
  margin-bottom: -50px;
  position: relative;
  background: linear-gradient(${MariaPrimary} 0%, ${MariaSecondGradientColor} 100%);
  // background: ${MariaPrimary};
  color: ${MariaSecondary},
  & p::selection {
    background: ${MariaSecondary}; 
    color: ${MariaPrimary};
  }
  & p::-moz-selection {
    background: ${MariaSecondary}; 
    color: ${MariaPrimary};
  }
  & input::placeholder, textarea::placeholder {
    opacity: 1;
  }
  & input:focus, textarea:focus {
    box-shadow:
    0 0 25px #fff,
    -10px 0 80px ${MariaSecondGradientColor},
    10px 0 80px ${MariaText};
  }
`

const Footer = styled.footer`
  -webkit-transform: translate(-50%, 0);
  position: relative;
  left: 50%;
  text-align: center;
  font-size: 0.85rem;
  color: ${MariaText};
  background: rgba(0, 0, 0, 0);  
  width: 100%;
  padding: 3rem 0 1rem 0;
`

export default Layout
