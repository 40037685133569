import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {MariaSecondary, MariaText} from "../utils/colors"
import { rhythm, scale } from "../utils/typography"

const NavigationMenu = () => {
    return (
        <NavWrapper>
          <h1   
            style={{
              ...scale(0.6),
              marginBottom: rhythm(0.1),
              marginTop: 0,
              paddingTop: `1rem`,
              fontSize: `1rem`,
              textAlign: `center`,
              color: `${MariaSecondary}`,
            }}>
            Maria Dermentzi</h1>
          <Navigation>
            <NavUl>
              <NavLi>
                <NavLink to="/">
                  Home
                </NavLink>
              </NavLi>
              <NavLi>
                <NavLink to="/portfolio/">
                  Portfolio
                </NavLink>
              </NavLi>
              <NavLi>
                <NavLink to="/blog/">
                  Blog
                </NavLink>
              </NavLi>
              <NavLi>
                <NavLink to="/contact/">
                  Contact
                </NavLink>
              </NavLi>
            </NavUl>
          </Navigation>
          </NavWrapper>
    );
  };

const NavWrapper = styled.div`
    margin: 0 0 1rem 0;
    padding: 0;
    `

const Navigation = styled(props => <nav {...props} />)`
    text-align: center;
    padding: 0;
`
const NavUl = styled(props => <ul {...props} />)`
    margin: 0;
`
const NavLi = styled(props => <li {...props} />)`
    display: inline-block;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-bottom: 0.2rem;
    font-size: 0.9rem;
    margin-bottom: 0;
    `

const NavLink = styled(props => <Link {...props} />)`
    color: ${MariaText};
    position: relative;
    text-decoration: none;
    display: inline-block;
    &:hover {
      color: ${MariaText};      
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${MariaSecondary};
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.2s ease-in-out 0s;
    }
    &:hover:before {
      visibility: visible;
      transform: scaleX(1);
    }

    `

  
    export default NavigationMenu