// custom typefaces
import 'typeface-press-start-2p'
import 'typeface-open-sans'
import Typography from "typography"
import { MariaSecondary} from "../utils/colors"

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.78,
  headerFontFamily: [
    "Press Start 2P",
    "serif",
  ],
  bodyFontFamily: ["Open Sans", "sans-serif"],
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    'h1': {
      fontSize: "1.4rem"
    },
    'h2,h3,h5': {
      fontSize: "1.2rem",
      color: MariaSecondary,
    },
    'a': {
      color: MariaSecondary,
      textDecoration: "none",
    },
    'a:hover': {
      textDecoration: 'none',
    },
    'h4': {
      fontSize: "1.3rem",
      fontFamily: `"Open Sans"`, //this controls the card headers
      lineHeight: `1.2`,
      width: `100%`,
      padding: `0 0.5rem`,
      textShadow: `1px 1px 5px rgba(0,0,0,1)`,
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
